import React, {createContext, Dispatch, SetStateAction, useEffect, useState} from "react";
import {User} from "../models/enums/userModel";
import {checkInternalUser} from "../utils/helpers";
import {useContentProxyData} from "../hooks/useContentProxyData";
import {ContentProxyAppSettings} from "../models/contentProxyModel";
import {environmentVariables} from "../utils/helpers/environmentVariables";
import {ssoLogin, submitLogOut} from "../utils/helpers/submitForm";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

interface LogoutResponse {
    status: number;
    // Add other properties if needed
}

export const LoginContext = createContext<{
    isLoggedIn: boolean;
    updateUser: (user: User) => void,
    setLoginStatus: (value: boolean, user: User) => void;
    user: User | undefined;
    additionalDataFailed: boolean;
    setAdditionalDataFailed: Dispatch<SetStateAction<boolean>>;
    isInternalUser: boolean;
    isVip: boolean;
    logOut(): void
}>({
    isLoggedIn: false,
    updateUser: () => {
    },
    setLoginStatus: () => {
    },
    user: undefined,
    additionalDataFailed: false,
    setAdditionalDataFailed: () => {
    },
    isInternalUser: false,
    isVip: false,
    logOut: () => {
    },
});

export function LoginProvider(props: { children: React.ReactNode; }) {
    const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
    const [user, setUser] = useState<User | undefined>(undefined)
    const [additionalDataFailed, setAdditionalDataFailed] = useState<boolean>(false)
    const [isInternalUser, setIsInternalUser] = useState<boolean>(false)
    const [isVip, setIsVip] = useState(false)
    const {data} = useContentProxyData<ContentProxyAppSettings>('appsettings')
    const history = useHistory();

    const updateUser = (userObj: User | undefined) => {
        if (userObj) {
            setUser(userObj)
        }
    }
    const setLoginStatus = (loggedIn: boolean, userObj: User | undefined) => {
        setLoggedIn(loggedIn);
        setUser(userObj)
        let isInternal;

        if (loggedIn && userObj) {
            localStorage.setItem('isLoggedIn', 'true')
            localStorage.setItem('userData', JSON.stringify(userObj))
            isInternal = checkInternalUser(userObj.emailAddress, data?.app_emailDomainWhitelist)
        } else {
            localStorage.removeItem('isLoggedIn')
            localStorage.removeItem('userData')
        }
        setIsInternalUser(!!isInternal)
    };

    const logOut = async () => {

        if (process.env.REACT_APP_LOGOUT_URL) {
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("userData");
            const logoutResponse: LogoutResponse | unknown = await submitLogOut(process.env.REACT_APP_LOGOUT_URL);
            if (logoutResponse && typeof logoutResponse === 'object' && 'status' in logoutResponse && (logoutResponse as { status: number }).status === 200) {
                setLoginStatus(false, undefined);
                history.push("/");
            }
        } else {
            toast.error("There has been a problem logging out.");
        }
    }

    useEffect(() => {
        const getloginData = async () => {
            let ssoData = await ssoLogin();
            if (ssoData['status'] === "success") {
                setLoginStatus(true, ssoData.userDetails)
                // Check if the user is an internal user
                const isInternal = checkInternalUser(ssoData.userDetails.emailAddress, data?.app_emailDomainWhitelist)
                setIsInternalUser(!!isInternal)
            } else {
                setLoginStatus(false, undefined)
            }
        };

        const userDetailsString = localStorage.getItem('userData')
        if (userDetailsString) {
            const userDetails = JSON.parse(userDetailsString)
            setLoginStatus(true, userDetails)
            // Check if the user is an internal user
            const isInternal = checkInternalUser(userDetails.emailAddress, data?.app_emailDomainWhitelist)
            setIsInternalUser(!!isInternal)
        } else {
            getloginData()
        }
        setIsVip(environmentVariables.specialVersion() === 'vip');
    }, [data])

    return (
        <LoginContext.Provider value={{
            isLoggedIn,
            setLoginStatus,
            user,
            isInternalUser,
            isVip,
            additionalDataFailed,
            setAdditionalDataFailed,
            updateUser,
            logOut
        }}>
            {props.children}
        </LoginContext.Provider>
    );
}
