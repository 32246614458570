import React, {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import './BoatGraphProgressWidget.scss';
import {RaceDataContext} from '../../context/raceData.context';
import {BoatDataContext} from '../../context/boatData.context';
import clsx from 'clsx';
import ChartViewer from './ChartViewer';
import ausCountryFlag from "../../assets/countryAssets/Aus.svg";
import denCountryFlag from "../../assets/countryAssets/Den.svg";
import fraCountryFlag from "../../assets/countryAssets/Fra.svg";
import gbrCountryFlag from "../../assets/countryAssets/Gbr.svg";
import japCountryFlag from "../../assets/countryAssets/Jap.svg";
import nzlCountryFlag from "../../assets/countryAssets/Nzl.svg";
import spaCountryFlag from "../../assets/countryAssets/Spa.svg";
import usaCountryFlag from "../../assets/countryAssets/Usa.svg";
import chnCountryFlag from "../../assets/countryAssets/Chn.svg";
import suiCountryFlag from "../../assets/countryAssets/Sui.svg";
import canCountryFlag from "../../assets/countryAssets/Can.svg";
import gerCountryFlag from "../../assets/countryAssets/Ger.svg"
import {ApexOptions} from 'apexcharts';
import {BoatStatusEnum} from '../../models/enums/boatStatusEnum';

const BoatGraphProgressWidget = () => {
    const raceData = useContext(RaceDataContext);
    const boatData = useContext(BoatDataContext);

    const [series, setSeries] = useState<ApexAxisChartSeries>();
    const [leadBoatAnnotation, setLeadBoatAnnotation] = useState<ApexAnnotations>();
    const [options, setOptions] = useState<ApexOptions>();

    const [width, setWidth] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current && ref.current.clientWidth) {
            setWidth(ref.current.clientWidth);
        }
    }, [ref?.current?.clientWidth]);

    const leadBoatId = raceData?.leadBoatId;

    const leadBoatRemainingIndex = useMemo(() => {
        if (!leadBoatId || !series) {
            return undefined;
        }
        const leadBoat = boatData.find((boat: { boatId: number; }) => boat.boatId === leadBoatId);

        if (!leadBoat) return undefined;
        return series.findIndex((boat) => boat.name === leadBoat.countryCode) + 1;
    }, [boatData, leadBoatId, series]);

    const mappedImages = useMemo(() => {
        if (!series) {
            return [];
        }
        const images: string[] = [];

        series.forEach((series, i) => {
            switch (series.name) {
                case "AUS":
                    images.splice(i, 0, ausCountryFlag);
                    return;
                case "DEN":
                    images.splice(i, 0, denCountryFlag);
                    return;
                case "ESP":
                    images.splice(i, 0, spaCountryFlag);
                    return;
                case "FRA":
                    images.splice(i, 0, fraCountryFlag);
                    return;
                case "GBR":
                    images.splice(i, 0, gbrCountryFlag);
                    return;
                case "JPN":
                    images.splice(i, 0, japCountryFlag);
                    return;
                case "USA":
                    images.splice(i, 0, usaCountryFlag);
                    return;
                case "NZL":
                    images.splice(i, 0, nzlCountryFlag);
                    return;
                case "CHN":
                    images.splice(i, 0, chnCountryFlag);
                    return;
                case "CAN":
                    images.splice(i, 0, canCountryFlag);
                    return;
                case "SUI":
                    images.splice(i, 0, suiCountryFlag);
                    return;
                case "GER":
                    images.splice(i, 0, gerCountryFlag);
                    return;
                default:
                    return;
            }
        });
        return images;
    }, [series]);


    useEffect(() => {
            const apexSeries: ApexAxisChartSeries = [];
            boatData
                .slice()
                .sort((a, b) => {
                    if (!a.rank && !b.rank) {
                        return a.countryCode.localeCompare(b.countryCode);
                    }
                    if (a.rank !== 0 && b.rank === 0) return 1;
                    if (a.rank === 0 && b.rank !== 0) return -1;
                    if (!b.rank) {
                        return 1;
                    }
                    if (!a.rank) {
                        return -1;
                    }

                    return b.rank - a.rank;
                })
                .filter(boat => (boat.boatStatus === BoatStatusEnum.Racing
                    || boat.boatStatus === BoatStatusEnum.Prestart
                    || boat.boatStatus === BoatStatusEnum.OCS
                    || boat.boatStatus === BoatStatusEnum.None))
                .forEach((boat, i) => {
                    apexSeries.push({
                        name: boat.countryCode,
                        data: [[boat && boat.estimatedProgress ? boat.estimatedProgress : 0, i + 0.5]]
                    })
                });

            setSeries(apexSeries);

            setLeadBoatAnnotation({
                position: 'back',
                yaxis: [
                    {
                        y: leadBoatRemainingIndex ? leadBoatRemainingIndex - 0.1 : -1,
                        y2: leadBoatRemainingIndex && leadBoatRemainingIndex - 0.1 - 1,
                        strokeDashArray: 0,
                        borderColor: '#e23b25',
                        borderWidth: 5,
                        fillColor: '#061a1b',
                        opacity: 1
                    }
                ]
            });

        }, [boatData, leadBoatRemainingIndex]
    );

    useLayoutEffect(() => {
        setOptions({

            ...options,
            annotations: leadBoatAnnotation,
            grid: {
                position: 'front',
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    // to hide the left leadboat border
                    left: -5
                }
            },
            chart: {
                sparkline: {
                    enabled: true
                },
                fontFamily: 'FoundersGroteskWeb , -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
                type: 'scatter',
                width: '100%',
                height: '100%',
                zoom: {
                    enabled: false,
                    type: "xy"
                },
                toolbar: {
                    show: false
                },
                animations: {
                    enabled: false
                }
            },
            xaxis: {
                labels: {
                    show: false
                },
                min: -0.1,
                max: 1.075,
                axisBorder: {
                    show: false
                },
                crosshairs: {
                    show: false
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                tickAmount: series ? series.length : 0,
                min: 0,
                max: series ? series.length : 0,
                show: true,
                axisBorder: {
                    width: 2,
                    show: true,
                    color: '#66c1c4',
                    offsetX: series && series.length <= 4 ? width / 8.5 : width / 9.5,
                    offsetY: 0
                },
                labels: {
                    show: false
                }
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'middle',
                formatter: function (val, opt) {
                    return opt.w.globals.initialSeries[opt.seriesIndex].name;
                },
                offsetX: 5,
                offsetY: series && series.length <= 4 ? 13 : 9,
                style: {
                    fontSize: series && series.length <= 4 ? '19px' : '13px',
                    fontWeight: 'bold',
                    colors: ['#e23b25']
                },
                background: {
                    enabled: true,
                    borderRadius: 2,
                    borderColor: '#e23b25',
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                }
            },
            markers: {
                width: series && series.length <= 4 ? 50 : 36,
                height: series && series.length <= 4 ? 30 : 22,
                size: series && series.length <= 4 ? 50 : 36,
                colors: undefined,
                strokeColors: '#fff',
                strokeWidth: 0,
                strokeOpacity: 1,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "square",
                radius: 0,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: false,
            },
            fill: {
                type: 'image',
                image: {
                    src: mappedImages,
                    width: series && series.length <= 4 ? 50 : 36,
                    height: series && series.length <= 4 ? 30 : 22,
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                enabled: false
            },
        });
    }, [boatData, leadBoatRemainingIndex, width, leadBoatAnnotation, mappedImages, series]);


    return (
        <div className={clsx('boatGraphProgressWidgetContainer', {
            'finishLine': true
        })}
             ref={ref}>
            <ChartViewer options={options} series={series} title="Product Trends by Month"/>
        </div>
    );
};


export default BoatGraphProgressWidget;
