import {CountryCodeEnum} from "./enums/countryCodeEnum";

interface ContentProxyObject {
    contentfulId: string;
    contentfulType: string;
    created: string;
    updated: string;
    ctActivityId:string;
}

interface ContentProxyLatLon {
    lat: number;
    lon: number;
}

interface ContentProxyImage extends ContentProxyObject {
    title: string;
    file: ContentProxyFile;
}

interface ContentProxyFile {
    url: string;
    details: string | undefined;
    fileName: string;
    contentType: string;
}

export interface ContentProxyRaceEvent extends ContentProxyObject {
    eventBannerLogo: ContentProxyImage;
    name: string;
    shortName: string;
    shortNameSubtitle: string;
    eventSeason: number;
    eventNumber: number;
    locationName: string;
    countryName: string;
    location?: ContentProxyLatLon;
    introduction?: string;
    preRaceSummary: string;
    postRaceSummary: string;
    startDateTime: string;
    endDateTime: string;
    countryAbbreviation: string;
    venueImage?: ContentProxyImage;
    websiteUrl: string;
    ticketsUrl: string;
    ticketAvailableState: TicketAvailableEnum;
    winner?: unknown;
    raceDays?: ContentProxyRaceDay[];
    eventHeading?: string;
    eventIntroImage?: ContentProxyImage;
    links?: unknown;
    liveFeed?: unknown;
    eventLogo?: ContentProxyImage;
    nextEventLogo?: ContentProxyImage;
    eventLabel?: string;
    standings?: unknown[];
    leaderboardInfo?: string;
    appLeaderboard?: unknown;
    racingTeams: unknown[];
    sponsor: ContentProxyAppSponsors;
    appCountryFlag: ContentProxyImage;
    insightsTicketsUnavailableText?: string;
    insightsUpcomingEventText?: string;
}

export enum ContentProxyRaceDayStatus {
    NotStarted = 'notStarted',
    InProgress = 'inProgress',
    Finished = 'finished',
    ReplayAvailable = 'replayAvailable'
}

export enum TicketAvailableEnum {
    yes = 'Yes',
    no = 'No',
    soldOut = 'Sold Out'
}

export interface ContentProxyRaceDay extends ContentProxyObject {
    name: string;
    raceDayBroadcastConfig: RaceDayBroadcastConfig[];
    shortName: string;
    mediumName?: string;
    startDateTime: string;
    endDateTime: string;
    state: ContentProxyRaceDayStatus;
    races: ContentProxyRace[];
    replayStreams?: ReplayStreams;
    liveStreams?: ContentProxyRaceLiveStream[];
    appLeaderboard?: unknown;
}

export interface ContentProxyNextRace extends ContentProxyObject {
    name: string;
    shortName: string;
    mediumName?: string;
    startDateTime: string;
    endDateTime: string;
    state: ContentProxyRaceDayStatus;
    races: ContentProxyRace[];
    replayStreams?: ReplayStreams;
    liveStreams?: ContentProxyRaceLiveStream[];
    appLeaderboard?: unknown;
}


export interface ReplayStreams {
    startTime: string;
    streams: Streams[];
    contentfulId: string;
    contentfulType: string;
    insightsVideoStartOffsetSeconds: number;
    raceStartTimeOffsetMillis: number;
    endOfVideoInSeconds: number;
}

export interface Streams {
    contentfulId: string
    contentfulType: string
    created: string
    description: string
    name: string
    thumbnailImage: unknown
    type: string
    updated: string
    url: string
}

export interface ContentProxyRace extends ContentProxyObject {
    name: string;
    shortName: string;
    formattedName: string;
    raceId: number;
    startDateTime: string;
    endDateTime: string;
    winner: unknown;
    timeOverride: string;
    appLeaderboard: unknown;
}

export interface ContentProxyRaceLiveStream {
    vdnUrl: string;
    cdnUrl: string;
    config: LiveStreamConfig;
    insightsWebLatencyMilliseconds: number;
    dataOnlyDelayMillis: number;
    eventStreams: ExternalVideo[];
    hyperlinkUrl: string;
}

export interface LiveStreamConfig {
    [broadcastConfigId: string]: LiveStreamConfigData;
}

export interface LiveStreamConfigData {
    streams: string[];
    broadcastRegion: string;
}

export interface ContentProxyTeam {
    nationPatternTiltShift: ContentProxyImage;
    teamPhoto: ContentProxyImage;
    name: string;
    fullName: string;
    code?: CountryCodeEnum;
    hexColor: string;
    introduction: string;
    logo?: ContentProxyImage;
    boatSchematicImage?: ContentProxyImage;
    wins?: unknown[];
    skipperImage?: ContentProxyImage;
    athletesLabel?: string;
    athletes?: unknown[];
    staff?: unknown[];
    sponsorLabel?: string;
    dataBoatId: number;
    contentfulId?: string;
    teamDescriptiveName: string;
    teamVersionType: string;
    // TODO: this model is not complete yet!
}

export interface ContentProxyAppSettings {
    app_androidMinimumVersionNo: string
    app_broadcasterConfigUrl: string
    app_bufferingTimeLimitSeconds: number
    app_minimumVersionNo: string
    app_newsUrl: string
    app_onboardingWelcomeText: string
    app_onboardingWelcomeVideoYouTubeId: string
    app_qrApiBaseUrl: string
    app_qrApiKey: string
    app_rolexBannerEnabled: boolean
    app_rolexBannerNativeUrl: string
    app_rolexBannerUrl: string
    app_seasonLeaderboardYear: number
    app_shopUrl: string
    app_sponsors: ContentProxyAppSponsors[],
    app_emailDomainWhitelist: string[]
}

export interface ContentProxyAppSponsors {
    contentfulId: string
    contentfulType: string
    created: string
    logo: ContentProxyImage
    name: string
    updated: string
    url: string
}

export enum ContentfulType {
    RaceEvent = 'RaceEvent',
    RaceDay = 'RaceDay'
}

export enum RaceDayBroadcastConfig {
    InsightsWebAllUsers = 'InsightsWebAllUsers',
    InsightsWebInternal = 'InsightsWebInternal',
    InsightsMobileAllUsers = 'InsightsMobileAllUsers',
    InsightsMobileVip = 'InsightsMobileVip',
}

export interface ContentProxyNextRaceDateAndTime {
    startDateTime: string
    endDateTime: string
    eventName: string
    contentfulType: ContentfulType
}

export interface ExternalVideo extends ContentProxyObject {
    title: string
    videoId: string
    videoProvider: "Oracle" | "YouTube" | "DailyMotion" | "Youku"
    enhancedPermissionsRequired: boolean
}
