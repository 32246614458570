import React, {
    ChangeEvent,
    MouseEvent,
    useContext,
    useEffect,
    useState,
} from "react";
import "./VideoActions.scss";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import {Box, Button, Modal} from "@mui/material";
import {CommentaryType} from "../../../../context/StreamDetailsProvider";
import {StreamDetailsContextState} from "../../../../context/streamDetails.context";

import {TimerContext} from "../../../../context/timer.context";
import {makeStyles} from "@mui/styles";
import {useHistory} from "react-router-dom";
import {submitForm} from "../../../../utils/helpers/submitForm";
import is from "date-fns/esm/locale/is/index.js";

export interface VideoControlsProps {
    isRaceSchedule: boolean | undefined;
    muted: boolean;
    playing: boolean;
    handlePlayPause: (e: MouseEvent<HTMLElement>) => void;
    handleMuteUnmute: (e: MouseEvent<HTMLElement>) => void;
    volume: number;
    handleVolumeMouseDown: (e: MouseEvent<HTMLInputElement>) => void;
    handleVolumeChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleVolumeMouseUp: (e: MouseEvent<HTMLInputElement>) => void;
    isReplay?: boolean;
    showPlayPause?: boolean;
    showVolumeSlider: boolean;
    isPodcastAvailable?: boolean;
    setShowVolumeSlider: React.Dispatch<React.SetStateAction<boolean>>;
    streamDetails?: StreamDetailsContextState;
    rewardPointsAvailable?: boolean;
}

const useStyles = makeStyles({
    ctSuccessModal: {
        position: "absolute",
        top: "13%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        fontSize: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "20%",
        justifyContent: "space-around",
        boxSizing: "border-box",
        "&:focus-visible": {
            outline: "none",
        },
    },
    ctSuccessModalContent: {
        position: "relative",
        padding: "16px",
        "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "white",
            boxShadow: "10px 0px 20px 0px #010606",
            transform: "skew(-14deg)",
            zIndex: -1,
        },
    },
    ctSuccessPoints: {
        position: "absolute",
        backgroundColor: "#061a1b",
        color: "white",
        width: "20%",
        bottom: "20%",
        fontSize: "12px",
        padding: '6px',
        textAlign: 'center',
        fontWeight: 'bold',
        lineHeight: '8px',
        transform: "skew(-10deg)",
    }
});
export const VideoControls = ({
                                  handleVolumeMouseUp,
                                  isRaceSchedule,
                                  handlePlayPause,
                                  playing,
                                  setShowVolumeSlider,
                                  muted,
                                  handleMuteUnmute,
                                  showVolumeSlider,
                                  volume,
                                  handleVolumeMouseDown,
                                  handleVolumeChange,
                                  showPlayPause = false,
                                  streamDetails,
                                  isPodcastAvailable = false,
                                  rewardPointsAvailable,
                              }: VideoControlsProps) => {
    const {
        minutes,

        start,
        pause,
        timeForReward,
        rewardPoints,
        isActivitySearchFailure,
        isActivitySearchSuccess,
    } = useContext(TimerContext);

    const timerSet = () => {
        !playing ? start() : pause();
    };
    const [close, setClose] = useState(false);
    const [videoExit, setVideoPlayedExit] = useState(false);
    const [isCreditResponse, setCreditResponse] = useState(false);
    const submitActivityCredit = async () => {
        let timerData = {
            activity_id: localStorage.getItem("ctActivityID"),
            num_points: rewardPoints,
        };
        try {
            const timerResponse = await submitForm(
                timerData,
                "user/user-activity-credit"
            );

            if (
                timerResponse &&
                timerResponse["status"] !== undefined &&
                timerResponse["status"] === "success" &&
                timerResponse["data"] === '{"awarded":true}'
            ) {
                setCreditResponse(true);
            } else if (timerResponse["status"] === "error") {
                setCreditResponse(false);
            }
        } catch (error) {
        }
    };
    useEffect(() => {
        if (
            minutes &&
            minutes === timeForReward - 1 &&
            rewardPointsAvailable &&
            isActivitySearchSuccess &&
            !isActivitySearchFailure
        ) {
            setVideoPlayedExit(true);
            submitActivityCredit();
            setClose(true);
        } else {
            setTimeout(handleClose, 5000);
        }
    }, [minutes]);
    const handleClose = () => {
        setVideoPlayedExit(false);
        setClose(true);
    };

    const classes = useStyles();

    return (
        <div className="videoActionsContainer">
            {!isRaceSchedule && showPlayPause && (
                <Tooltip title={!playing ? "Play" : "Pause"} placement="top">
                    <IconButton
                        onClick={(e) => {
                            handlePlayPause(e);
                            timerSet();
                        }}
                    >
                        {!playing ? <PlayArrowIcon/> : <PauseIcon/>}
                    </IconButton>
                </Tooltip>
            )}
            {minutes && minutes === timeForReward && isCreditResponse && (
                <Modal open={videoExit} onClose={() => handleClose()}>
                    <Box className={classes.ctSuccessModal}>
                        <div className={classes.ctSuccessModalContent}> {/* Add a wrapper element */}
                            <div className="videoActionForm__homepageContainer">
                                <div className="videoActionForm__imageSection">
                                    <img
                                        src={"/Dock_wheel.svg"}
                                        alt=""
                                        style={{width: "150px"}}
                                    />
                                    <span className={classes.ctSuccessPoints}>+{rewardPoints} Points</span>


                                </div>
                                <div className="videoActionForm__textSection">
                                    <h1 className="videoActionForm__header">
                                        Congratulations!
                                        <p className="videoActionForm__text">
                                            You have successfully completed this activity
                                        </p>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            )}

            {minutes &&
                minutes === timeForReward &&
                isActivitySearchSuccess &&
                !isCreditResponse && (
                    <Modal open={videoExit} onClose={() => handleClose()}>
                        <Box className={classes.ctSuccessModal}>
                            <div className="videoActionForm__homepageContainer">
                                <div className="videoActionForm__imageSection">
                                    <img
                                        src={"/Question.svg"}
                                        alt=""
                                        style={{width: "150px"}}
                                    />
                                </div>

                                <div className="videoActionForm__textSection">
                                    <p className="videoActionForm__text">
                                        We couldn’t process your points at this time. Our team is
                                        looking into this!
                                        <p className="videoActionForm__text">
                                            We’re very sorry for any inconvenience caused.
                                        </p>
                                    </p>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                )}

            <div
                className="volumeContainer"
                onMouseEnter={() => setShowVolumeSlider(true)}
                onMouseLeave={() => setShowVolumeSlider(false)}
            >
                <Tooltip title={!muted ? "Mute" : "Unmute"} placement="top">
                    <IconButton onClick={handleMuteUnmute}>
                        {!muted ? <VolumeUpIcon/> : <VolumeOffIcon/>}
                    </IconButton>
                </Tooltip>
                {showVolumeSlider && (
                    <input
                        className="volumeSlider"
                        type="range"
                        min={0}
                        max={1}
                        step="any"
                        value={volume}
                        onMouseDown={handleVolumeMouseDown}
                        onChange={handleVolumeChange}
                        onMouseUp={handleVolumeMouseUp}
                    />
                )}
            </div>

            {streamDetails?.commentaryType && isPodcastAvailable && (
                <div className="commentaryTypeSelector">
                    {streamDetails.commentaryType.map((type: CommentaryType) => (
                        <Button
                            className={`commentary__button ${type.isSelected}`}
                            key={type.key}
                            variant={type.isSelected ? "contained" : "outlined"}
                            onClick={() =>
                                streamDetails.handleCommentarySelect
                                    ? streamDetails.handleCommentarySelect(type)
                                    : null
                            }
                        >
                            {type.key}
                        </Button>
                    ))}
                </div>
            )}
        </div>
    );
};
