import React, {useContext, useMemo} from "react";
import "./PredictedToFinishWidget.scss";
import {FormatTimeInHours, Round} from "../../utils/helpers";
import {RaceDataContext} from "../../context/raceData.context";
import {BoatDataContext} from "../../context/boatData.context";
import {TimestampContext} from "../../context/timestamp.context";
import clsx from "clsx";
import PopStatCard from '../../components/popStatCards';
import UseCountryFlag from '../../hooks/useCountryFlag';
import {countryCodeNameMap} from '../../utils/helpers/mapCountryCodes';

const PredictedToFinishWidget = () => {
    const raceData = useContext(RaceDataContext);
    const boatData = useContext(BoatDataContext);
    const [timeData] = useContext(TimestampContext);

    const leadBoatRemaining = useMemo(() => {
        if (!raceData?.leadBoatId) {
            return undefined;
        }

        return boatData.find((boat) => boat.boatId === raceData.leadBoatId);
    }, [boatData, raceData?.leadBoatId]);

    // 4 hours in milliseconds
    const timeUntilFinishThresholdMs = 4 * 3600 * 1000;
    const renderStateValue = () => {
        if (timeData && leadBoatRemaining && leadBoatRemaining.estimatedTimeFinishMs > 0) {

            const timeUntilFinishMs = leadBoatRemaining.estimatedTimeFinishMs - timeData;
            if (timeUntilFinishMs < timeUntilFinishThresholdMs) {
                return FormatTimeInHours(Round((timeUntilFinishMs) / 1000, 0), false);
            }
        }

        return '--';
    };

    return (
        <div className={clsx('boatIcons, predictedToFinishWidgetContainer', leadBoatRemaining?.countryCode)}>
            <div className="leaderImages">
                <span className="countryImage">{UseCountryFlag(leadBoatRemaining?.countryCode, {width: '48px', height: '28px'})}</span>
                {
                    leadBoatRemaining?.countryCode
                        ? <span
                            className="countryLabel">{countryCodeNameMap[leadBoatRemaining?.countryCode.toLowerCase()]?.toUpperCase()}</span>
                        : <span className="countryLabel">--</span>
                }
            </div>
            <span className="popStatWrapper">
                <PopStatCard
                    data={{label: 'PREDICTED TO FINISH IN', value: renderStateValue(), widthPercentage: '56%'}}/>
            </span>
        </div>
    );
};

export default PredictedToFinishWidget;
