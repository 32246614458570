import React from "react";
import ausCountryFlag from "../assets/countryAssetsV2/AUS.jpg";
import denCountryFlag from "../assets/countryAssetsV2/DEN.jpg";
import fraCountryFlag from "../assets/countryAssetsV2/FRA.jpg";
import gbrCountryFlag from "../assets/countryAssetsV2/GBR.jpg";
import japCountryFlag from "../assets/countryAssetsV2/JPN.jpg";
import nzlCountryFlag from "../assets/countryAssetsV2/NZL.jpg";
import spaCountryFlag from "../assets/countryAssetsV2/ESP.jpg";
import usaCountryFlag from "../assets/countryAssetsV2/USA.jpg";
import itaCountryFlag from "../assets/countryAssetsV2/ITA.png";
import bmuCountryFlag from "../assets/countryAssetsV2/BMU.png";
import chnCountryFlag from "../assets/countryAssetsV2/CHN.png";
import uaeCountryFlag from "../assets/countryAssetsV2/UAE.png";
import suiCountryFlag from "../assets/countryAssetsV2/SUI.png";
import canCountryFlag from "../assets/countryAssetsV2/CAN.png";
import gerCountryFlag from "../assets/countryAssetsV2/GER.png";


const UseCountryFlag = (teamCountryCode: string | undefined, layout: { width: string, height: string }) => {

    switch (teamCountryCode) {
        case "AUS":
            return <img src={ausCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "DEN":
            return <img src={denCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "ESP":
            return <img src={spaCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "FRA":
            return <img src={fraCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "GBR":
            return <img src={gbrCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "JPN":
            return <img src={japCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "USA":
            return <img src={usaCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "NZL":
            return <img src={nzlCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "BMU":
            return <img src={bmuCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "ITA":
            return <img src={itaCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "CHN":
            return <img src={chnCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "UAE":
            return <img src={uaeCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "CAN":
            return <img src={canCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "SUI":
            return <img src={suiCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        case "GER":
            return <img src={gerCountryFlag} alt="teamCountryCode"
                        style={{width: layout.width, height: layout.height}}/>;
        default:
            return ''
    }

};

export default UseCountryFlag;
